import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Contacto from "../components/contacto/Contacto";
// import Head from '../components/main/Head';
// import Preloader from '../components/main/Preloader';
// import Body from '../components/main/Body';
// import Header from '../components/main/Header';
// import Footer from '../components/main/Footer';

function App() {
  return (
    <div className="App">
      {/* <Head></Head> */}
      {/*<Preloader></Preloader> */}
      <div id="root"></div>
      {/* <Body></Body> */}
      {/* <Header></Header>  */}
      {/* <Footer></Footer> */}
      <Router>
        <div>
          {/* <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
          </ul>
        </nav> */}

          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/contacto">
              <Contacto />
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
