import React from "react";
// import{ init } from 'emailjs-com';
// init("user_u77PPKfrg9ss5nSgWpeCF");
const templateId = "template_xhtr0hc";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
      sendStatus: undefined,
      sendError: undefined,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  cleanFormAndState() {
    this.setState({ name: "" });
    this.setState({ email: "" });
    this.setState({ subject: "" });
    this.setState({ phone: "" });
    this.setState({ message: "" });
    document.getElementsByName("name")[0].value = "";
    document.getElementsByName("email")[0].value = "";
    document.getElementsByName("subject")[0].value = "";
    document.getElementsByName("phone")[0].value = "";
    document.getElementsByName("message")[0].value = "";
  }

  handleChange(event) {
    switch (event.target.name) {
      case "name":
        this.setState({ name: event.target.value });
        break;
      case "email":
        this.setState({ email: event.target.value });
        break;
      case "subject":
        this.setState({ subject: event.target.value });
        break;
      case "phone":
        this.setState({ phone: event.target.value });
        break;
      case "message":
        this.setState({ message: event.target.value });
        break;
      default:
        return false;
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true });

    var contactMessage = {
      from_name: this.state.name,
      reply_to: this.state.email,
      subject: this.state.subject,
      phone: this.state.phone,
      message_html: this.state.message,
    };

    this.sendFeedback(templateId, contactMessage);
    return false;
  }

  sendFeedback(templateId, variables) {
    window.emailjs
      .send("default_service", templateId, variables)
      .then((res) => {
        this.setState({ sendStatus: true });
        this.setState({ loading: false });
        this.cleanFormAndState();
      })
      .catch((err) => {
        this.setState({ sendStatus: false });
        this.setState({ loading: false });
        this.setState({ sendError: err.text });
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occured:",
          err
        );
      });
  }

  render() {
    return (
      <html lang="es">
        <head>
          <meta charSet="utf-8"></meta>
          <meta httpEquiv="x-ua-compatible" content="ie=edge"></meta>
          <meta name="description" content=""></meta>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          ></meta>

          <title>Colich - Colegio en línea Chile</title>

          <link
            rel="shortcut icon"
            href="images\favicon_48x48.ico"
            type="icon"
          ></link>

          <link rel="stylesheet" href="css\slick.css"></link>

          <link rel="stylesheet" href="css\animate.css"></link>

          <link rel="stylesheet" href="css\nice-select.css"></link>

          <link rel="stylesheet" href="css\jquery.nice-number.min.css"></link>

          <link rel="stylesheet" href="css\magnific-popup.css"></link>

          <link rel="stylesheet" href="css\bootstrap.min.css"></link>

          <link rel="stylesheet" href="css\font-awesome.min.css"></link>

          <link rel="stylesheet" href="css\default.css"></link>

          <link rel="stylesheet" href="css\style.css"></link>

          <link rel="stylesheet" href="css\responsive.css"></link>
        </head>

        <body>
          <div className="preloader">
            <div className="loader rubix-cube">
              <div className="layer layer-1"></div>
              <div className="layer layer-2"></div>
              <div className="layer layer-3 color-1"></div>
              <div className="layer layer-4"></div>
              <div className="layer layer-5"></div>
              <div className="layer layer-6"></div>
              <div className="layer layer-7"></div>
              <div className="layer layer-8"></div>
            </div>
          </div>

          <header id="header-part">
            <div className="navigation">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <nav className="navbar navbar-expand-lg">
                      <a className="navbar-brand" href="index.html">
                        <img src="images\logo.png" alt="Logo"></img>
                      </a>
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                      </button>

                      <div
                        className="collapse navbar-collapse sub-menu-bar"
                        id="navbarSupportedContent"
                      >
                        <ul className="navbar-nav ml-auto">
                          <li className="nav-item">
                            <a href="index.html">Inicio</a>
                          </li>
                          <li className="nav-item">
                            <a href="proyecto-educativo.html">Nosotros</a>
                            <ul className="sub-menu">
                              <li>
                                <a href="proyecto-educativo.html">
                                  Proyecto Educativo
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-item">
                            <a href="cursos.html">Colegio en línea</a>
                            <ul className="sub-menu">
                              <li>
                                <a href="cursos-basica.html">
                                  Enseñanza Básica
                                </a>
                              </li>
                              <li>
                                <a href="cursos-media.html">Enseñanza Media</a>
                              </li>
                              <li>
                                <a href="cursos-apoyo-docente.html">
                                  Apoyo Docente
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-item">
                            <a href="events.html">Admisión</a>
                            <ul className="sub-menu">
                              <li>
                                <a href="matriculas-pago.html">Matrículas</a>
                              </li>
                              <li>
                                <a href="aranceles.html">Aranceles</a>
                              </li>
                              <li>
                                <a href="terminos-condiciones.html">
                                  Términos y condiciones
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-item">
                            <a href="https://colegioenlineachile.atlassian.net/servicedesk/customer/portal/3/group/23/create/18">Portal de ayuda</a>
                            <ul className="sub-menu">
                              <li>
                                <a href="ayuda.html">Ayuda</a>
                              </li>
                              <li>
                                <a href="faq.html">Preguntas Frecuentes</a>
                              </li>
                              <li>
                                <a href="reglamento.html">Reglamento</a>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-item">
                            <a className="active" href="/contacto">
                              Contacto
                            </a>
                          </li>
                          <li className="sub-menu"></li>
                          <li>
                            <a href="#">
                              <i className="fa fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <div className="search-box">
            <div className="search-form">
              <div className="closebtn">
                <span></span>
                <span></span>
              </div>
              <form action="#">
                <input type="text" placeholder="Search by keyword"></input>
                <button>
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </div>
          </div>

          <section
            id="page-banner"
            className="pt-105 pb-130 bg_cover"
            data-overlay="8"
            style={{ backgroundImage: "url(images/page-banner-1.jpg)" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-banner-cont">
                    <h2>Contacto</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="index.html">Inicio</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Contacto
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="contact-page" className="pt-90 pb-120 gray-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <div className="contact-from mt-30">
                    <div className="section-title">
                      <h5>Contáctenos</h5>
                      <h2>Sigamos en contacto</h2>
                    </div>
                    <div className="main-form pt-45">
                      <form
                        id="contact-form"
                        //action="javascript:void(0);"
                        method="post"
                        // data-toggle="validator"
                        onSubmit={this.handleSubmit.bind(this)}
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="single-form form-group">
                              <input
                                name="name"
                                type="text"
                                placeholder="Nombre"
                                onChange={this.handleChange}
                                data-error="El nombre es requerido."
                                required="required"
                              ></input>
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="single-form form-group">
                              <input
                                name="email"
                                type="email"
                                placeholder="Correo"
                                onChange={this.handleChange}
                                data-error="Se requiere un correo válido."
                                required="required"
                              ></input>
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="single-form form-group">
                              <input
                                name="subject"
                                type="text"
                                placeholder="Asunto"
                                onChange={this.handleChange}
                                data-error="El asunto es requerido."
                                required="required"
                              ></input>
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="single-form form-group">
                              <input
                                name="phone"
                                type="text"
                                placeholder="Teléfono"
                                onChange={this.handleChange}
                                data-error="El teléfono es requerido."
                                required="required"
                              ></input>
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="single-form form-group">
                              <textarea
                                name="message"
                                placeholder="Mensaje"
                                onChange={this.handleChange}
                                data-error="Por favor, deje un mensaje."
                                required="required"
                              ></textarea>
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="single-form form-group">
                                {this.state.loading ? (
                                  <div
                                    className="alert alert-warning"
                                    role="alert"
                                  >
                                    <div className="spinner-border text-warning"></div>
                                    &nbsp; Enviando el mensaje
                                  </div>
                                ) : (
                                  ""
                                )}
                              {this.state.sendStatus ? (
                                <div
                                  className="alert alert-success"
                                  role="alert"
                                >
                                  Mensaje enviado correctamente
                                </div>
                              ) : this.state.sendError !== undefined ? (
                                <div
                                  className="alert alert-danger"
                                  role="alert"
                                >
                                  Ha ocurrido un erro durante el envio{" "}
                                  {this.state.sendError} :{" "}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="single-form">
                              <button type="submit" className="main-btn">
                                Enviar
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="contact-address mt-30">
                    <ul>
                      <li>
                        <div className="single-address">
                          <div className="icon">
                            <i className="fa fa-home"></i>
                          </div>
                          <div className="cont">
                            <p>
                              Las bellotas 199, Oficina 62, Providencia,
                              Santiago
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="single-address">
                          <div className="icon">
                            <i className="fa fa-envelope-o"></i>
                          </div>
                          <div className="cont">
                            <p>contacto@colegioenlineachile.cl</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="map mt-30">
                    <div id="contact-map"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <footer id="footer-part">
            <div className="footer-top pt-40 pb-70">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="footer-about mt-40">
                      <div className="logo">
                        <a href="#">
                          <img src="images\logoblancoazul.png" alt="Logo"></img>
                        </a>
                      </div>
                      <p>Somos el colegio en línea de Chile</p>
                      <ul className="mt-20">
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-google-plus"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="footer-link mt-40">
                      <div className="footer-title pb-25">
                        <h6>Mapa del sitio</h6>
                      </div>
                      <ul>
                        <li>
                          <a href="index.html">
                            <i className="fa fa-angle-right"></i>Inicio
                          </a>
                        </li>
                        <li>
                          <a
                            href="proyecto-educativo.html"
                            style={{ display: "inline" }}
                          >
                            <i className="fa fa-angle-right"></i>Proyecto
                            Educativo
                          </a>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <a href="cursos.html">
                            <i className="fa fa-angle-right"></i>Colegio en
                            línea
                          </a>
                        </li>
                        <li>
                          <a href="matriculas-pago.html">
                            <i className="fa fa-angle-right"></i>Admisión
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-6">
                    <div className="footer-link support mt-40">
                      <div className="footer-title pb-25">
                        <h6>Soporte</h6>
                      </div>
                      <ul>
                        <li>
                          <a href="faq.html">
                            <i className="fa fa-angle-right"></i>Preguntas
                            frecuentes
                          </a>
                        </li>
                        <li>
                          <a href="ayuda.html">
                            <i className="fa fa-angle-right"></i>Portal de ayuda
                          </a>
                        </li>
                        <li>
                          <a href="reglamento.html">
                            <i className="fa fa-angle-right"></i>Reglamento
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="footer-address mt-40">
                      <div className="footer-title pb-25">
                        <h6>Contactanos</h6>
                      </div>
                      <ul>
                        <li>
                          <div className="icon">
                            <i className="fa fa-home"></i>
                          </div>
                          <div className="cont">
                            <p>
                              Las bellotas 199, Oficina 62, Providencia,
                              Santiago, Chile
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <i className="fa fa-phone"></i>
                          </div>
                          <div className="cont">
                            <p>+56 9 3578 0224</p>
                          </div>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="fa fa-phone"></i>
                            </div>
                            <div class="cont">
                                <p>+56 9 8884 1761</p>
                            </div>
                          </li>
                        <li>
                          <div className="icon">
                            <i className="fa fa-envelope-o"></i>
                          </div>
                          <div className="cont">
                            <p>contacto@colegioenlineachile.cl</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer-copyright pt-10 pb-25">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="copyright text-md-left text-center pt-15">
                      <p>&copy; Todos los derechos reservados.</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="copyright text-md-right text-center pt-15">
                      <p>
                        Desarrollado por&nbsp;<span>COLICH</span>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>

          <a href="#" className="back-to-top">
            <i className="fa fa-angle-up"></i>
          </a>

          <script src="js\vendor\modernizr-3.6.0.min.js"></script>
          <script src="js\vendor\jquery-1.12.4.min.js"></script>

          <script src="js\bootstrap.min.js"></script>

          <script src="js\slick.min.js"></script>

          <script src="js\jquery.magnific-popup.min.js"></script>

          <script src="js\waypoints.min.js"></script>
          <script src="js\jquery.counterup.min.js"></script>

          <script src="js\jquery.nice-select.min.js"></script>

          <script src="js\jquery.nice-number.min.js"></script>

          <script src="js\jquery.countdown.min.js"></script>

          <script src="js\validator.min.js"></script>

          <script src="js\ajax-contact.js"></script>

          <script src="js\main.js"></script>

          <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDC3Ip9iVC0nIxC6V14CKLQ1HZNF_65qEQ"></script>
          <script src="js\map-script.js"></script>
        </body>
      </html>
    );
  }
}

//export default Contacto;
